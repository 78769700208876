import request from '@/utils/request.js';
var url = "/manage/waiter/data/analysis";

/**
 * 分页查询
 * @param params
 */
export function findPage(params) {
    return request({
        url: `${url}/findPage`, method: 'get', params: params
    })
}

/**
 * 我的统计数据
 */
export function myData() {
    return request({
        url: `${url}/myData`, method: 'get'
    })
}

/**
 * 分页查询
 * @param params
 */
export function download(params) {
    return request({
        url: `${url}/download`, method: 'get', params: params
    })
}