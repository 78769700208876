<template>
    <el-row>
        <el-dialog :title="studentPro.title" v-if="studentPro.show" :visible.sync="studentPro.show" width="60%" append-to-body>
            <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="studentPro.action"
                    :headers="headers"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="fileList"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    :before-upload="handleBefore"
                    :auto-upload="true">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <el-button style="margin-left: 10px;" size="small" type="warning" @click="downloadExcel">
                    下载模板
                </el-button>
                <div slot="tip" class="el-upload__tip">只能上传excel文件，且不超过7M大小</div>
                <div slot="tip" class="el-upload__tip" style="font-weight: bold;color: #f56c6c">
                    注意：使用Excel导入时请注意，必须保证字段在系统中真实存在，否则存入的字段将为空
                </div>
                <div slot="tip" class="el-upload__tip" v-if="isShowResult">{{ msg }}</div>
                <div slot="tip" class="el-upload__tip" v-if="isShowResult" v-for=" resMsg in msgArr">{{ resMsg }}</div>
            </el-upload>
        </el-dialog>
    </el-row>
</template>

<script>
import axios from "axios";

export default {
    name: "uploadStudent",
    props: {
        studentPro: {
            type: Object,
            default: {
                show: false,
                title: "学员基础信息",
                action: "/api/manage/waiter/student/uploadDetail",
                template: "/api/manage/waiter/student/downloadTemplateDetail"
            }
        },
        token: {
            require: true,
            default: null,
            type: String
        },
        callback: {
            require: true,
            type: Function,
            default: function () {
            },
        }
    },
    data() {
        return {
            headers: {"version": localStorage.getItem("_version"),},
            fileList: [],
            msgArr: [],
            totalCount: 0,
            successCount: 0,
            isShowResult: false
        }
    },
    methods: {
        submitUpload() {
            this.$refs.upload.submit();
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        /**
         * 下载模板
         */
        async downloadExcel() {
            axios({
                method: "get",
                url: this.studentPro.template,
                headers: {
                    "version": localStorage.getItem("_version"),
                },
                responseType: 'blob',
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = this.studentPro.title + '导入模板.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }).catch(error => {
                this.$message.error("导出模板失败");
            })
        },
        /**
         * 图片上传
         */
        handleBefore(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            const extension = testmsg === 'xls'
            const extension2 = testmsg === 'xlsx'
            const isLt2M = file.size / 1024 / 1024 < 7
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
            }
            if (!isLt2M) {
                this.$message({
                    message: '上传文件大小不能超过 7MB!',
                    type: 'warning'
                });
            }
            return extension || extension2 && isLt2M
        },
        /**
         * 上传成功的回调方法
         * @param res
         * @param file
         * @param fileList
         */
        handleSuccess(res, file, fileList) {
            if (res.success) {
                this.$message.success(res.msg)
                this.isShowResult = true
            } else {
                this.$message.warning(res.msg)
            }
        },
        /**
         * 文件上传失败
         * @param err
         * @param file
         * @param fileList
         */
        handleError(err, file, fileList) {
            this.$message.error("文件上传失败，请联系管理员")
        }
    },
    watch: {
        "studentPro.show": function (newVal, oldVal) {
            if (newVal) {
                this.msgArr = []
                this.msg = ""
                this.isShowResult = false
            }
        }
    },
    computed: {}
}
</script>

<style scoped>

</style>
