import request from '@/utils/request.js';

var url = "/manage/waiter/receive/btn";

/**
 * 分页查询
 * @param params
 */
export function findPage(params) {
    return request({
        url: `${url}/findPage`, method: 'get', params: params
    })
}

/**
 * 查询
 * @param params
 */
export function findList(params) {
    return request({
        url: `${url}/findList`, method: 'get', params: params
    })
}

/**
 * 禁用
 * @param arr
 */
export function handleDisable(arr) {
    return request({url: `${url}/handleDisable`, method: 'delete', data: arr})
}

/**
 * 启用
 * @param arr
 */
export function handleEnable(arr) {
    return request.put(`${url}/handleEnable`, arr)
}

/**
 * 保存数据(新增/修改)
 * @param params
 */
export function saveData(params) {
    return request.post(`${url}/saveData`, JSON.stringify(params))
}

/**
 * 获取详情
 * @param btnId
 */
export function info(btnId) {
    return request({
        url: `${url}/info?btnId=${btnId}`, method: 'get'
    })
}

/**
 * 查询领取按钮学员数据
 * @param btnId
 * @param params
 */
export function findReceiveStudent(btnId, params) {
    return request({
        url: `${url}/findReceiveStudent?btnId=${btnId}`, method: 'get', params: params
    })
}


/**
 * 领取学员
 */
export function receiveStudent(receiveId, serviceId, studentId) {
    return request({
        url: `${url}/receiveStudent/${receiveId}/${serviceId}/${studentId}`, method: 'get'
    })
}

/**
 * 获取领取数据
 * @param btnId
 */
export function getReceiveSize(btnId) {
    return request({
        url: `${url}/getReceiveSize/${btnId}`, method: 'get'
    })
}