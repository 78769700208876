import request from '@/utils/request.js';

var url = "/manage/waiter/services";

/**
 * 分页查询
 * @param params
 */
export function findPage(params) {
    return request({
        url: `${url}/findPage`, method: 'get', params: params
    })
}
/**
 * 分页查询
 * @param params
 */
export function findList(params) {
    return request({
        url: `${url}/findList`, method: 'get', params: params
    })
}

/**
 * 查询我的服务列表
 */
export function findMyService() {
    return request({
        url: `${url}/findMyService`, method: 'get'
    })
}

/**
 * 禁用
 * @param arr
 */
export function handleDisable(arr) {
    return request({url: `${url}/handleDisable`, method: 'delete', data: arr})
}

/**
 * 启用
 * @param arr
 */
export function handleEnable(arr) {
    return request.put(`${url}/handleEnable`, arr)
}

/**
 * 保存数据(新增/修改)
 * @param params
 */
export function saveData(params) {
    return request.post(`${url}/saveData`, JSON.stringify(params))
}